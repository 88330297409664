import React, { FC } from 'react';
import SpectarLayout from '../../../components/PagesLayouts/SpectarLayout';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import { TNamesArray } from '../../../types/types';
import FixedButton from '../../../components/common/FixedButton';
import { useScrollTo } from '../../../hooks/useScrollToTop';
import { useGetScreenWidth } from '../../../hooks/useGetScreenWidth';

const FullCredits: FC = () => {
    const clientsArray: TNamesArray = [
        { id: 0, name: 'Nikolai Suvorov,  ', position: 'CEO', subtype: 'ml-5' },
        { id: 1, name: 'Anthony Corrado', position: 'CTO', subtype: 'ml-5' },

        { id: 2, name: 'Meredith Zohar,', position: 'CPO', subtype: 'ml-5' },
    ];

    const managersArray: TNamesArray = [
        { id: 0, name: 'Yurii Drozd,', position: 'CEO', subtype: 'ml-5' },
        { id: 1, name: 'Dmytrii Stepanenko,', position: 'Account Manager', subtype: 'ml-5' },
    ];

    const architectsArray: TNamesArray = [
        { id: 0, name: 'Yurii Drozd,', position: 'CEO', subtype: 'ml-5' },
    ];

    const developersArray: TNamesArray = [
        {
            id: 0,
            name: 'Oleksandr Lomakovskyi,',
            position: 'Sr. Software Engineer',
            subtype: 'ml-5',
        },
        { id: 1, name: 'Gleb Gerasimenko, ', position: 'Sr. Software Engineer', subtype: 'ml-5' },
    ];

    const qaArray: TNamesArray = [
        { id: 0, name: 'Anastasiia Shyshak,', position: 'Senior QA', subtype: 'ml-5' },
    ];

    const designersArray: TNamesArray = [
        { id: 0, name: 'Ivan Zaichuk,', position: 'Full-stack UX Architect', subtype: 'ml-5' },
    ];

    const isMobile = useGetScreenWidth();
    const scrollValue = isMobile ? 2300 : 1300;

    useScrollTo(0, scrollValue);

    return (
        <SpectarLayout>
            <div className="scope-of-project__wrapper">
                <SectionTitle type="primary" title="Full credits" />
                <div className="scope-of-project__body">
                    <div className="scope-of-project__description scope-of-project__description--mt">
                        <div className="scope-of-project__item">
                            <SectionTitle subtype="mb-30" type="secondary" title="Spectar io." />
                            <Span type="headline" subtype="mb-10">
                                Client managers
                            </Span>
                            {clientsArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>

                        <div className="scope-of-project__item">
                            <SectionTitle type="secondary" subtype="mb-30" title="Blackbird" />
                            <Span type="headline" subtype="mb-10">
                                Managers
                            </Span>
                            {managersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                            <Span type="headline" subtype="m-10">
                                System architects
                            </Span>
                            {architectsArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Developers
                            </Span>
                            {developersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}

                            <Span type="headline" subtype="m-10">
                                Quality control
                            </Span>
                            {qaArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                            <Span type="headline" subtype="m-10">
                                Designers
                            </Span>
                            {designersArray.map((client) => (
                                <div key={client.id} className="scope-of-project__name">
                                    <Span type="span" subtype="">
                                        {client.name}
                                    </Span>
                                    <Span type="paragraph" subtype={client.subtype}>
                                        {client.position}
                                    </Span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Links />
                </div>
            </div>
            <FixedButton />
        </SpectarLayout>
    );
};

export default FullCredits;
